.projects-banner__container {
  width: 100%;
  background-image: url(https://res.cloudinary.com/kboyle/image/upload/v1587858468/Portfolio/pool.svg);
  min-height: 380px;
  background-size: cover;
  background-position: center;
}
.projects-banner__title {
  color: white;
  font-size: 2.75em;
  text-shadow: 1px 1px 1px #00000073, -.5px -.5px 0px rgba(199, 80, 80, 0.63);
  font-family: "Didact Gothic", sans-serif;
  text-align: right;
  padding-right: 16%;
}
