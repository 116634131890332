.contact-banner {
  height: 250px;
  background-image: url(https://res.cloudinary.com/kboyle/image/upload/v1587537963/Portfolio/body-bg.svg);
  background-repeat: repeat-y;
  background-size: cover;
  opacity: 0.85;
  position: relative;
  overflow: hidden;
}
.contact-banner__image {
  height: 250px;
  width: 100%;
  opacity: 0.3;
  object-fit: cover;
}
.contact-banner__title {
  color: white;
  text-shadow: 1px 1px 1px #000000c2;
  font-family: "Didact Gothic", sans-serif;
  position: absolute;
  top: 45%;
  right: 16%;
  font-size: 45px;
}
