.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  border-bottom: 0.5px solid #ffa90e8c;
  height: 7.8vh;
  z-index: 4;
}
.nav__inner {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
}
.nav__links--right {
  display: flex;
  justify-content: space-between;
  width: 35%;
  min-width: 250px;
  margin-left: 5%;
}
.nav__link {
  color: #4c4943;
  padding: 2%;
  font-family: "Carrois Gothic SC", sans-serif;
  transition: text-shadow 0.1s;
}
.nav__link:hover {
  cursor: pointer;
  text-shadow: 0.5px 0.5px 0px #0202029c;
}
.nav__link:active {
  transform: scale(1.025);
  text-shadow: 0px 1px 5px #ffee0087;
}
.logo {
  max-height: 7.1vh;
  object-fit: contain;
  margin-top: 1vh;
  margin-left: 5vw;
  transition: 0.6s transform ease-out;
}
.logo:hover {
  transform: rotateY(180deg);
}
.logo:active {
  transform: rotateX(180deg);
}
.nav__link--highlighted {
  background-color: #fe7f01;
  color: white;
  animation: .8s highlight ease-in;
}
.nav__link--reverse-highlighted {
  background-color: white;
  animation: .7s reverse-highlight ease-out;
}

@media screen and (max-width: 500px) {
  .nav__link {
    font-size: 16px;
  }
  .nav__links--right {
    width: 65%;
    min-width: 200px;
  }
}

@keyframes highlight {
  from {
    background-color: white;
    color: #4c4943;
  }
  to {
    background-color: #fe7f01;
    color: white;
  }
}

@keyframes reverse-highlight {
  from {
    background-color: #fe7f01;
    color: white;
  }
  to {
    background-color: white;
    color: #4c4943;
  }
}
