.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: #1d1d1d;
  background-color: #000000cc;
  font-family: "Didact Gothic", sans-serif;
  animation: fade-in-image 0.5s;
}
.lightbox__button {
  border: 0;
}
.lightbox__button:hover {
  color: white;
  cursor: pointer;
  text-shadow: 0px 0px 1px black;
}
.lightbox__image {
  height: auto;
  max-width: 600px;
  width: 80vw;
  object-fit: contain;
  border-radius: 6px;
  transition: height .2s, width .2s;
  animation: fade-in-image 2s;
}
.lightbox__close-button {
  color: purple;
  font-size: 80px;
  position: fixed;
  background-color: transparent;
  top: 5%;
  right: 5%;
  padding: 0% 0% 0.5% 0.5%;
}
.lightbox__next-button {
  color: black;
  text-shadow: 0px 0px 1px white;
  font-size: 36px;
  padding: 1% 3%;
  position: fixed;
  bottom: 10%;
  right: 5%;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
  background-color: purple;
  color: #ecf0f1;
  transition: background-color .3s;
}
.lightbox__next-button:hover {
  background-color: rgb(104, 1, 104);
}

.lightbox__next-button:active {
  color: yellow;
  text-shadow: 0px 0px 3px white;
}

@keyframes fade-in-image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}