@import url('https://fonts.googleapis.com/css?family=Carrois+Gothic+SC|Didact+Gothic|Josefin+Sans');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}
.button-reset {
  all: unset;
}
.button-reset:focus {
  outline: none;
}
