.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  border: 0.5px solid #afafaf;
  border-radius: 8px;
  width: 90vw;
  max-width: 370px;
  margin-bottom: 10%;
}
.card--top {
  background: rgb(232, 235, 244);
  background: radial-gradient(
    circle,
    rgb(252, 252, 252) 0%,
    rgb(217, 221, 234) 100%
  );
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 6%;
}
.card--top img {
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgb(29, 29, 29);
  max-height: 220px;
}

.card--bottom {
  padding: 6% 5%;
  background-color: #f9f9f9;
  text-align: justify;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.card__title {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
  font-size: 1.3rem;
  margin: 0;
}
.card__link-container {
  line-height: 1.3;
}
.card__link-container:last-child {
  margin-top: 2%;
}
.card h5 {
  margin: 0;
}

@media screen and (min-width: 750px) and (max-width: 1249px) {
  .card {
    flex-direction: row;
    max-width: 650px;
    margin-bottom: 5%;
  }
  .card--top {
    border-radius: 8px 0 0 8px;
    padding: 2%;
    width: 34%;
  }
  .card--top img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    box-shadow: 1px 2px 4px 0px rgb(29 29 29 / 10%);
  }
  .card--bottom {
    width: 66%;
    border-radius: 0 8px 8px 0;
    height: 100%;
  }
  .card__title {
    text-align: left;
  }
}

@media screen and (min-width: 1250px) {
  .card {
    height: 555px;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .card--top {
    height: 260px;
  }
}
