.gallery-card {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  border: 0.5px solid black;
  overflow: hidden;
  transition: box-shadow 0.5s;
  box-shadow: 0px 0px 8px 2px #ffffff94;
}
.gallery-card:hover {
  box-shadow: 0px 0px 10px 4px #ffffff85;
}
.gallery-card__image {
  background-color: white;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: height 0.3s ease-in, width 0.3s ease-in;
}
.gallery-card__image:hover {
  height: 120%;
  width: 110%;
  cursor: pointer;
}


@media screen and (max-width: 500px) {
  .gallery-card {
    width: 75px;
    height: 75px;
  }
}

@media screen and (max-width: 330px) {
  .gallery-card {
    width: 65px;
    height: 65px;
  }
}
