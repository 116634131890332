.about-container {
  font-family: "Carrois Gothic", sans-serif;
  min-height: 100vh;
  width: 80%;
  max-width: 500px;
  margin: 2% auto;
  padding: 8%;
}
.about-container__upper {
  width: 90%;
}
.about-container__upper-half {
  width: 50%;
  height: 100%;
  padding: 2%;
  margin-bottom: 5%;
}
.about-container__upper--left {
  font-size: 14vw;
  color: #2f2f2f;
  font-weight: bold;
}
.about-container__upper--right {
  font-size: 0.9rem;
}
.skills-list {
  columns: 2;
  column-gap: 10px;
  max-width: 500px;
  list-style: circle inside;
  list-style-position: outside;
  font-size: 16px;
}
.skills-list li {
  margin-bottom: 2px;
}


@media screen and (min-width: 900px) {
  .about-container {
    max-width: 650px;
    line-height: 1.2;
  }
  .about-container__upper--left {
    font-size: 140px;
  }
  .about-container__upper--right,
  .about-container__lower {
    font-size: 1rem;
  }
  .about-container__lower {
    text-align: justify;
  }
}

@media screen and (max-width: 650px) {
  .about-container__upper--right {
    font-size: 16px;
  }
  .about-container__lower {
    font-size: 16px;
  }
}
