.contact-container {
  height: 100vh;
  border-bottom: 5.6vh solid #3b3b3b;
  position: relative;
}
.contact-page__inner {
  width: 100%;
  height: 100%;
  background-image: url(https://res.cloudinary.com/kboyle/image/upload/c_scale,w_1049/v1588984244/Portfolio/sky-bg_compressed.jpg);
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1050px) {
  .contact-page__inner {
    background-image: url(https://res.cloudinary.com/kboyle/image/upload/c_scale,w_1800/v1588984244/Portfolio/sky-bg_compressed.jpg)
  }
}
@media screen and (min-width: 1801px) {
  .contact-page__inner {
    background-image: url(https://res.cloudinary.com/kboyle/image/upload/v1588984244/Portfolio/sky-bg_compressed.jpg)
  }
}
.contact-page__box {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-page__box--top-left {
  background: linear-gradient(
    90deg,
    rgb(197, 19, 184) 0%,
    rgba(233, 30, 99, 0.72) 0%,
    rgba(233, 30, 99, 0.75) 100%
  );
  background: #e91e63a8;
}
.contact-page__box--top-right {
  background: linear-gradient(
    90deg,
    rgb(41, 135, 210) 0%,
    rgba(30, 196, 233, 0.72) 0%,
    rgba(30, 214, 233, 0.75) 100%
  );
  background: #2196f36e;
}
.contact-page__box--bottom-left {
  background: linear-gradient(
    90deg,
    rgb(41, 210, 46) 0%,
    rgba(33, 233, 30, 0.72) 0%,
    rgba(61, 193, 66, 0.65) 100%
  );
  background: #8bc34a69;
}
.contact-page__box--bottom-right {
  background: linear-gradient(
    90deg,
    rgb(202, 139, 47) 0%,
    rgba(255, 152, 0, 0.76) 0%,
    rgba(218, 171, 28, 0.77) 100%
  );
  background: #ff9800ad;
}
.icon {
  height: 17vw;
  transition: height 0.2s ease-out;
}
.icon:hover {
  height: 18.5vw;
}
.github-icon,
.instagram-icon,
.linkedin-icon {
  border-radius: 23%;
  box-shadow: 3px 2px 8px #0000008c;
} 
@media screen and (max-width: 650px) {
  .icon {
    height: 30vw;
  }
  .icon:hover {
    height: 31.5vw;
  }
}
