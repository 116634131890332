.web-projects-list__title {
  text-align: center;
  max-width: 350px;
  margin: 10% auto;
  font-size: 2rem;
}

.web-projects-list__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1250px) {
  .web-projects-list__container {
    flex-direction: row;
    justify-content: center;
  }
}
